import { poisSelector } from "features/counter/counterSlice";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import "./appMap.scss";

export const AppMap = () => {
  const pois = useSelector(poisSelector);

  const getLink = (guid: string) => {
    return "/details?guid=" + guid;
  };
  //console.log(pois);
  return (
    <MapContainer
      center={[47.151726, 27.587914]}
      zoom={13}
      scrollWheelZoom={false}
      className="map"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {pois.map((poi) => {
        return (
          <Marker key={poi.guid} position={[poi.latitude, poi.longitude]}>
            <Popup maxWidth={250}>
              <a href={getLink(poi.guid)}>
                <strong>{poi.name}</strong>
              </a>
              <br />
              {poi.description}
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};
