import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PoiContract } from "model/poi-model";

interface State {
  pois: PoiContract[];
  value: number;
}

const initialState: State = {
  pois: [],
  value: 0,
};
export const counterSlice = createSlice({
  name: "counter",
  initialState: initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.value += 1;
      const pois = action.payload as PoiContract[];
      state.pois = pois;
    });
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = counterSlice.actions;

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async () => {
  const res = await fetch(process.env.REACT_APP_BASE_URL + "/api/Pois").then(
    (data) => data.json()
  );
  return res;
});

export const fetchPoiDetails = createAsyncThunk(
  "posts/poi/details",
  async ({ guid }: { guid: string }) => {
    const res = await fetch(
      process.env.REACT_APP_BASE_URL + "/api/Pois/" + guid
    ).then((data) => data.json());
    return res;
  }
);

export const selectCount = (state: State) => state.value;
export const poisSelector = (state: State) => state.pois;

export default counterSlice.reducer;
