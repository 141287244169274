export const AppFooter = () => {
  return (
    <footer className="main-footer">
      <strong>
        Copyright &copy; 2022{" "}
        <a href="http://hartadigitala.ro/" target="_blank">
          hartadigitala.ro
        </a>
        .{" "}
      </strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 0.0.1-alpha
      </div>
    </footer>
  );
};
