import { AppDashboard } from "appDashboard/appDashboard";
import { AppFooter } from "appFooter/appFooter";
import { AppHEader } from "appHeader/appHeader";
import { AppMenu } from "appMenu/appMenu";
import { AppPoiDetails } from "appPoiDetails/appPoiDetails";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
ReactGA.initialize("G-7Q4E3DPDCL");

function App() {
  ReactGA.send("pageview");

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          {/* //<Route path="/" element={<AppDashboard />}> */}
          <Route index element={<AppDashboard />} />
          <Route path="details" element={<AppPoiDetails />} />
          {/* //<Route path="blogs" element={<Blogs />} />
          //<Route path="contact" element={<Contact />} />
          //<Route path="*" element={<NoPage />} /> */}
          {/* //  </Route> */}
        </Routes>
      </BrowserRouter>

      <AppHEader />
      <AppMenu />
      <AppFooter />
    </div>
  );
}

export default App;
